var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-margin" },
    [
      _c("TitleComponent", {
        attrs: { placeholder: _vm.$t("fields.title"), item: _vm.item },
        on: { "title-TITLE_UPDATED": _vm.updateItem },
      }),
      _c("ComplexEditor", {
        staticClass: "pt-4",
        attrs: {
          questionnaire_id: _vm.parent_id,
          editor_data: _vm.editorData,
          placeholder: `${_vm.$t("description")}...`,
          autoOpen: _vm.autoOpenEditor,
          dontClose: _vm.dontCloseEditor,
        },
        on: {
          "update:editor_data": function ($event) {
            _vm.editorData = $event
          },
          blur: _vm.updateDescription,
          destroy: _vm.onDestroy,
          "data-changed": _vm.updateDescription,
        },
      }),
      _c("Footer", { attrs: { item: _vm.item } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }